import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { TextField, useRefresh, useTranslate } from 'react-admin';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Link from '@material-ui/core/Link';
import PersonIcon from '@material-ui/icons/Person';
import ReplayIcon from '@material-ui/icons/Replay';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import BloodPressureIcon from '../../../icons/BloodPressure';
import FormatField from '../../../components/fields/FormatField';
import FormatValueField from '../../../components/fields/FormatValueField';
import HorizontalShowLayout from '../../../components/HorizontalShowLayout';
import PulseIcon from '../../../icons/Pulse';
import PulseOximeterIcon from '../../../icons/PulseOximeter';
import RobotIcon from '../../../icons/Robot';
import Signature from '../../../components/media/Signature';
import SobrietyTestIcon from '../../../icons/SobrietyTest';
import TableArrayAdapter from '../../../components/tables/TableArrayAdapter';
import TableRow from '../../../components/tables/TableRow';
import TableRowPlaceholder from '../../../components/tables/TableRowPlaceholder';
import ThermometerIcon from '../../../icons/Thermometer';
import styles from './CheckupShow.module.css';
import useAsyncValue from '../../../hooks/useAsyncValue';
import useTelemedicService from '../../../hooks/useTelemedicService';

import { getRole } from '../../../storages/auth';
import { CheckupResult, Role } from '../../../constants';
import { RoutePath, getUrl, navigateTo } from '../../../routes';
import {
  formatDisplayDate, formatDisplayMoscowDateTime, formatDisplayTime,
  formatInspectionType, formatCheckupResult, formatPersonName, formatSex,
  formatNonAdmissionReason, formatQuantity,
} from '../../../utils/formatters';

const CHECKUP_RESULT_CLASSES = {};
CHECKUP_RESULT_CLASSES[CheckupResult.NOT_ADMITTED] = styles.danger;
CHECKUP_RESULT_CLASSES[CheckupResult.ADMITTED] = styles.info;
CHECKUP_RESULT_CLASSES[CheckupResult.AWAITING] = styles.warning;
CHECKUP_RESULT_CLASSES[CheckupResult.REPEAT] = null;

const CHECKUP_RESULT_ICONS = {};
CHECKUP_RESULT_ICONS[CheckupResult.NOT_ADMITTED] = CloseIcon;
CHECKUP_RESULT_ICONS[CheckupResult.ADMITTED] = CheckIcon;
CHECKUP_RESULT_ICONS[CheckupResult.AWAITING] = HourglassEmptyIcon;
CHECKUP_RESULT_ICONS[CheckupResult.REPEAT] = ReplayIcon;

const CheckupResultIcon = ({ result }) => {
  const resultIconClass = CHECKUP_RESULT_CLASSES[result];
  const ResultIcon = CHECKUP_RESULT_ICONS[result];
  return (
    <span className={resultIconClass} title={formatCheckupResult(result)}>
      <ResultIcon />
    </span>
  );
};

const MeasurementsTableRow = ({ item, userParameters }) => {
  const checkupData = item.attributes ? item.attributes : item;
  const {
    datetime_start, datetime_end,
    arterial_pressure, venous_pressure, pulse, alcohol, temperature, pulseoximeter,
    result_auto, result_medic, type
  } = checkupData;
  const {
    min_arterial_pressure, max_arterial_pressure,
    min_venous_pressure, max_venous_pressure,
    min_pulse, max_pulse,
    unacceptable_alcohol_level,
    alcohol_pass_value, alcohol_not_pass_value,
    min_temperature, max_temperature,
  } = userParameters;
  let alcohol_num;
  let alcohol_str;

  if (!isNaN(parseFloat(alcohol))) {
    alcohol_num = parseFloat(alcohol);
  } else {
    alcohol_str = alcohol.toString();
  }
  
  const handleClick = useCallback(() => {
    navigateTo(RoutePath.CHECKUP_SHOW, { id: item.id });
  }, [item.id]);
  const notAdmitted = result_auto === CheckupResult.NOT_ADMITTED || result_medic === CheckupResult.NOT_ADMITTED;
  const rowStyle = notAdmitted ? styles.notAdmittedRow : undefined;
  return (
    <TableRow onClick={handleClick} clickable hover={!notAdmitted} className={rowStyle}>
      <TableCell>
        {`${formatDisplayDate(datetime_start)} ${formatDisplayTime(datetime_start)} -
        ${formatDisplayTime(datetime_end)}`}
      </TableCell>
      <TableCell>
        {
          !!(arterial_pressure && venous_pressure) && (
            <span>
              <MeasurementResult
                minValue={min_arterial_pressure}
                maxValue={max_arterial_pressure}
                value={arterial_pressure}
              />
              &nbsp;/&nbsp;
              <MeasurementResult
                minValue={min_venous_pressure}
                maxValue={max_venous_pressure}
                value={venous_pressure}
              />
            </span>
          )
        }
      </TableCell>
      <TableCell>
        <MeasurementResult
          minValue={min_pulse}
          maxValue={max_pulse}
          value={pulse}
        />
      </TableCell>
        {
          typeof alcohol_num !== 'undefined' && (
            <TableCell>
              <MeasurementResult
                minValue={0}
                maxValue={unacceptable_alcohol_level}
                value={alcohol}
                unit="мг/л"
              />
            </TableCell>
          )
        } 
        {
          alcohol_str && (
            <TableCell>
              <MeasurementResultString
                neutralValue='-'
                passValue={alcohol_pass_value}
                notPassValue={alcohol_not_pass_value}
                value={alcohol}
              />
            </TableCell>
          )
        }
      <TableCell>
        <MeasurementResult
          minValue={min_temperature}
          maxValue={max_temperature}
          value={temperature}
        />
      </TableCell>
      <TableCell>
        <MeasurementResult
          value={pulseoximeter}
        />
      </TableCell>
      <TableCell><CheckupResultIcon result={result_auto} /></TableCell>
      <TableCell><CheckupResultIcon result={result_medic} /></TableCell>
      <TableCell>{formatInspectionType(type)}</TableCell>
    </TableRow>
  );
};

const HealthStatusLink = ({ record }) => {
  const translate = useTranslate();
  return (
    record ? (
      <Link href={getUrl(RoutePath.USER_HEALTH_STATUS, { userId: record.user.id })}>
        <h5>{translate('telemedic.users.show.healthReport')}</h5>
      </Link>
    ) : null
  );
};

const MeasurementsTable = ({ record }) => {
  const translate = useTranslate();
  const [checkupsError, setCheckupsError] = useState(false);
  const service = useTelemedicService();
  const userId = record ? record.user.id : undefined;
  const [recentCheckups] = useAsyncValue(
    (argUserId) => (
      service.getCheckups({
        userId: argUserId,
        pageSize: 11,
        sort: '-created_at',
      }).then((result) => (
        result.json.data.filter((checkup) => checkup.id !== record.id)
      ))
    ),
    userId,
    null,
    () => setCheckupsError(true),
  );
  const userParameters = record ? record.user : null;
  const rowProps = useMemo(() => ({ userParameters }), [userParameters]);
  return (
    <TableContainer className={styles.tableContainer}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <div title={translate('telemedic.checkups.show.checkupTime')}>
                <DateRangeIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.bloodPressure')}>
                <BloodPressureIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.pulse')}>
                <PulseIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.alcohol')}>
                <SobrietyTestIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.temperature')}>
                <ThermometerIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.pulseoximeter')}>
                <PulseOximeterIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.resultAuto')}>
                <RobotIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.resultMedic')}>
                <PersonIcon />
              </div>
            </TableCell>
            <TableCell className={styles.smallColumn}>
              <div title={translate('telemedic.checkups.show.checkupType')}>
                <SearchIcon />
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            record
              ? <MeasurementsTableRow item={record} userParameters={record.user} />
              : <TableRowPlaceholder columns={8} />
          }
          <TableRow>
            <TableCell colSpan={8}>
              <Typography variant="subtitle1" className={styles.centerText}>
                {translate('telemedic.checkups.show.recentCheckups')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableArrayAdapter
            columns={8}
            data={recentCheckups}
            error={checkupsError}
            emptyTextId="telemedic.checkups.show.noRecentCheckups"
            errorTextId="telemedic.checkups.show.recentCheckupsError"
            placeholderRows={7}
            Row={MeasurementsTableRow}
            rowProps={rowProps}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MeasurementResult = ({ minValue, maxValue, value, unit }) => {
  const translate = useTranslate();
  let compareResult;
  let className;
  let displayValue = value;
  
  if (typeof value !== 'undefined' && value !== null) {
    if (minValue && value < minValue) {
      compareResult = translate('telemedic.checkups.show.belowNorm');
      className = styles.danger;
    } else if (maxValue && value > maxValue) {
      compareResult = translate('telemedic.checkups.show.aboveNorm');
      className = styles.danger;
    } else {
      compareResult = translate('telemedic.checkups.show.normal');
      className = styles.info;
    }
    if (unit) {
      displayValue = `${value} ${unit}`;
    }
    return (
      <span className={className} title={compareResult}>{displayValue}</span>
    );
  }
  return null;
};


const MeasurementResultString = ({ neutralValue, passValue, notPassValue, value }) => {
  const translate = useTranslate();
  if (typeof value !== 'undefined' && value !== null && value !== 'no') {
    if (value === passValue || value === neutralValue) {
      return (
        <span className={styles.info} title={translate('telemedic.checkups.show.normal')}>{value}</span>
      );
    }
    return (
      <span className={styles.danger} title={translate('telemedic.checkups.show.aboveNorm')}>{value}</span>
    );
  }
  return null;
}

const CheckupLayout = (props) => {
  //Вынужденный костыль, так как иначе при быстром переходе от списка осмотров к кешированному осмотру сбрасываются данные
  const { record } = props;
  const refreshRecord = useRefresh();
  const [recordHasUpdated, setRecordHasUpdated] = useState(false);
  const [checkupRecord, setCheckupRecord] = useState({});
  if (!checkupRecord.terminal_name && !!record.terminal_name) {
    setCheckupRecord(record)
  }
  if (!record.terminal_name && !!checkupRecord.terminal_name && !recordHasUpdated) {
    setRecordHasUpdated(true);
    refreshRecord();
  }

  const translate = useTranslate();
  const formatBirthdayWithAge = (birthday) => {
    const formattedBirthday = formatDisplayDate(birthday);
    const age = moment().diff(birthday, 'years');
    const formattedAge = formatQuantity(age, 'год', 'года', 'лет');
    return `${formattedBirthday} (${formattedAge})`;
  };
  const formatResponsible = ({ user }) => {
    const { responsible_name, responsible_phone } = user.organization;
    const phonePrefix = translate('telemedic.checkups.show.responsiblePhonePrefix');
    if (responsible_name) {
      if (responsible_phone) {
        return `${responsible_name} (${phonePrefix} ${responsible_phone})`;
      }
      return responsible_name;
    }
    if (responsible_phone) {
      return responsible_phone;
    }
    return '';
  };
  const formatGraphTestResult = (testResult) => formatQuantity(testResult, 'ошибка', 'ошибки', 'ошибок');
  const formatSchulteTestResult = (testResult) => testResult || translate('telemedic.checkups.show.testNotPerformed');
  const formatColorCheckupResult = (checkupResult) => {
    let className;
    if (checkupResult === CheckupResult.AWAITING) className = styles.warning;
    else if (checkupResult === CheckupResult.ADMITTED) className = styles.info;
    else if (checkupResult === CheckupResult.NOT_ADMITTED) className = styles.danger;
    return <span className={className}>{formatCheckupResult(checkupResult)}</span>;
  };
  const formatBoolValue = (boolValue) => boolValue ? 'да' : 'нет';
  const formatNonAdmisionReasons = (nonAdmissionReasons) => {
    const reasonsListElements = nonAdmissionReasons
      ? nonAdmissionReasons.map((reason) => (
        <li key={reason}>{formatNonAdmissionReason(reason)}</li>
      ))
      : [];
    return (
      <ul className={styles.nonAdmissionReasonsList}>{reasonsListElements}</ul>
    );
  };
  return (
    <HorizontalShowLayout {...props}>
      <TextField label="telemedic.checkups.show.personnelNumber" source="user.personnel_number" />
      <FormatValueField
        label="telemedic.checkups.show.sex"
        source="user.sex"
        formatter={formatSex}
      />
      <FormatValueField
        label="telemedic.checkups.show.name"
        source="user"
        formatter={formatPersonName}
      />
      <Signature
        label="telemedic.checkups.show.signature"
        source="media.sign"
      />
      <FormatValueField
        label="telemedic.checkups.show.birthday"
        source="user.birthday"
        formatter={formatBirthdayWithAge}
      />
      <TextField label="telemedic.checkups.show.organization" source="user.organization.name" />
      <FormatValueField
        label="telemedic.checkups.show.checkupType"
        source="type"
        formatter={formatInspectionType}
      />
      <FormatField
        label="telemedic.checkups.show.responsible"
        formatter={formatResponsible}
      />
      <MeasurementsTable />
      <TextField label="telemedic.checkups.show.weight" source="weight" />
      <FormatValueField
        label="telemedic.checkups.show.graphTest"
        source="graph_test"
        fallback={translate('telemedic.checkups.show.testNotPerformed')}
        formatter={formatGraphTestResult}
      />
      <FormatValueField
        label="telemedic.checkups.show.schulte"
        source="schulte"
        fallback={translate('telemedic.checkups.show.testNotPerformed')}
        formatter={formatSchulteTestResult}
      />
      <FormatValueField
        label="telemedic.checkups.show.feelingGood"
        source="feeling_good"
        formatter={formatBoolValue}
      />
      <FormatValueField
        label="telemedic.checkups.show.healthComplaints"
        source="health_complaints"
        formatter={formatBoolValue}
      />
      <FormatValueField
        label="telemedic.checkups.show.feelRested"
        source="feel_rested"
        formatter={formatBoolValue}
      />
      <TextField label="telemedic.checkups.show.complaints" source="complaints.text" />
      <FormatValueField
        label="telemedic.checkups.show.resultAuto"
        source="result_auto"
        formatter={formatColorCheckupResult}
      />
      <FormatValueField
        label="telemedic.checkups.show.resultMedic"
        source="result_medic"
        formatter={formatColorCheckupResult}
      />
      <FormatValueField
        label="telemedic.checkups.show.nonAdmissionReasons"
        source="non_admission_reasons"
        formatter={formatNonAdmisionReasons}
      />
      <TextField label="telemedic.checkups.show.comment" source="comment" />
      <FormatValueField
        label="telemedic.checkups.show.medic"
        source="medic"
        formatter={formatPersonName}
      />
      <FormatValueField
        label="telemedic.checkups.show.resultDateTime"
        source="result_datetime"
        formatter={formatDisplayMoscowDateTime}
      />
      <TextField source="terminal_name" label="telemedic.checkups.show.terminal" />
      <FormatValueField
        label="telemedic.checkups.show.checkupPollStart"
        source="checkup_poll_start"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.checkupPollEnd"
        source="checkup_poll_end"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.pressStart"
        source="press_start"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.pressEnd"
        source="press_end"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.tempStart"
        source="temp_start"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.tempEnd"
        source="temp_end"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.alcStart"
        source="alc_start"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.alcEnd"
        source="alc_end"
        formatter={formatDisplayMoscowDateTime}
      />
      <FormatValueField
        label="telemedic.checkups.show.userConf"
        source="user_conf"
        formatter={formatBoolValue}
      />
      <HealthStatusLink />
    </HorizontalShowLayout>
  );
};

export default CheckupLayout;
