import React from 'react';
import {
  useNotify,
} from 'react-admin';

import AdmissionDialog from './AdmissionDialog';
import NonAdmissionDialog from './NonAdmissionDialog';
import PromiseProgressDialog from '../../components/PromiseProgressDialog';
import moment from 'moment';
import TelemedicService from '../../services/TelemedicService';

import { CheckupResult } from '../../constants';
import { getRutokenServiceInstance } from '../../services/RutokenService';
import { getAuthToken, isTokenAttached } from '../../storages/auth';

function withCheckupActions(WrappedComponent) {
  return function WithNotify(props) {
    const notify = useNotify();

    class WithCheckupActions extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          showAdmissionProgressDialog: false,
          showAdmissionDialog: false,
          showNonAdmissionDialog: false,
          showRepeatDialog: false,
          resultAuto: null,
          epl_gis_epd_api: false,
          partner_epl_file: '',
        };
        this.telemedicService = new TelemedicService(getAuthToken());
        this.rutokenService = getRutokenServiceInstance();
        this.checkupId = null;
        this.userId = null;
        this.callbacks = null;
        this.deviceId = null;
        this.certificateId = null;
        this.date = moment(new Date(2022, 9, 5)).format('YYYY-MM-DD');
        this.organizationId = null;
        this.encodedJournal = null;
      }

      componentWillUnmount() {
        this.telemedicService.abort();
      }

      admitUser = (checkupId, resultAuto, callbacks) => {
        if (resultAuto === CheckupResult.ADMITTED) {
          this.setState({
            showAdmissionProgressDialog: true,
          });
        } else {
          this.setState({
            showAdmissionDialog: true,
            resultAuto,
          });
        }
        this.checkupId = checkupId;
        this.callbacks = callbacks;
      }

      _admitUser = () => this._withDeviceAndCertificateId(
        ({deviceId, certificateId}) => this._signCheckupIfNeeded(deviceId, certificateId)
          .then(() => this._handleFetchPromise(this.telemedicService.admitUser(this.checkupId, certificateId))
        ),
      )

      getDoctorVerdict = (checkupId) => this._handleFetchPromise(
        this.telemedicService.getDoctorVerdict(checkupId),
      );

      getCheckupReport = (checkupId) => this._handleFetchPromise(
        this.telemedicService.getCheckupReport(checkupId),
      );

      getTaxiWaybill = (checkupId) => this._handleFetchPromise(
        this.telemedicService.getTaxiWaybill(checkupId),
      );

      getNonAdmissionPaper = (checkupId) => this._handleFetchPromise(
        this.telemedicService.getNonAdmissionPaper(checkupId),
      );

      getSobrietyProtocol = (checkupId) => this._handleFetchPromise(
        this.telemedicService.getSobrietyProtocol(checkupId),
      );

      getQRCode = (checkupId) => this._handleFetchPromise(
        this.telemedicService.getQRCode(checkupId),
      );

      notAdmitUser = (checkupId, resultAuto, callbacks) => {
        this.setState({
          showNonAdmissionDialog: true,
          resultAuto,
        });
        this.checkupId = checkupId;
        this.callbacks = callbacks;
      }

      _notAdmitUser = (nonAdmissionReasons, comment) => this._withDeviceAndCertificateId(
        ({certificateId}) => this._handleFetchPromise(
          this.telemedicService.notAdmitUser(this.checkupId, certificateId, nonAdmissionReasons, comment),
        ),
      )

      requestCheckupRepeat = (checkupId, callbacks) => {
        this.setState({
          showRepeatDialog: true,
        });
        this.checkupId = checkupId;
        this.callbacks = callbacks;
      }

      _requestCheckupRepeat = () => this._withDeviceAndCertificateId(
        ({certificateId}) => this._handleFetchPromise(
          this.telemedicService.requestCheckupRepeat(this.checkupId, certificateId),
        ),
      )

      notAdmitUserAuto = (checkupId) => this._withDeviceAndCertificateId(
        ({certificateId}) => this._handleFetchPromise(
          this.telemedicService.notAdmitUserAuto(checkupId, certificateId),
        ),
      )

      _withDeviceAndCertificateId = (promiseFactory) => {
        console.log('_withDeviceAndCertificateId');
        if (isTokenAttached()) {
          console.log('Token attached');
          return getRutokenServiceInstance().withCertificateIdAndDevice(promiseFactory)
            .catch((error) => {
              console.log(error);
              return Promise.reject({
                errorTextId: 'telemedic.checkupDialogs.tokenErrorText',
                error,
              });
            });
        }
        return promiseFactory({deviceId: null, certificateId: null});
      }

      _signCheckupIfNeeded = (deviceId, certificateId) => {
        const {epl_gis_epd_api} = this.state;
        const {partner_epl_file} = this.state;
        if (epl_gis_epd_api) {
          return this.telemedicService.attachCheckupEpl(this.checkupId).then(() => 
          this.rutokenService.getCertificatePublicKey(deviceId, certificateId)
          .then((certificatePublicKey) => this.signJournals(deviceId, certificateId, certificatePublicKey))
          ).catch((error) => {
            console.log(error);
            this.telemedicService.revertCheckupAdmit(this.checkupId);
            notify('Ошибка при подписании: ' + error.errorDescription, 'error');
            return Promise.reject({
              errorTextId: 'telemedic.checkups.show.sendGisEpdSignedError',
              error,
            });
          })
        }
        if (partner_epl_file) {
          return this.rutokenService.getCertificatePublicKey(deviceId, certificateId)
          .then((certificatePublicKey) => this.signJournalsForPartners(deviceId, certificateId, certificatePublicKey)
          ).catch((error) => {
            console.log(error);
            this.telemedicService.revertCheckupAdmit(this.checkupId);
            notify('Ошибка при подписании: ' + error.errorDescription, 'error');
            return Promise.reject({
              errorTextId: 'telemedic.checkups.show.sendPartnerEplSignedError',
              error,
            });
          })
        }
        return Promise.resolve();
      }



      _handleFetchPromise = (promise) => promise.catch((e) => {
        console.log(e);
        const result = {};
        if (e.errorDescription) {
          result.errorText = e.errorDescription;
        } else {
          result.errorTextId = 'telemedic.checkupDialogs.requestErrorText';
          result.error = e;
        }
        return Promise.reject(result)
      })

      _onCancel = () => {
        this.setState({
          showAdmissionProgressDialog: false,
          showAdmissionDialog: false,
          showNonAdmissionDialog: false,
          showRepeatDialog: false,
        });
        if (this.callbacks && this.callbacks.onCancel) {
          this.callbacks.onCancel();
        }
        this.callbacks = null;
        this.telemedicService.revertCheckupAdmit(this.checkupId)
      }

      _onDone = () => {
        this.setState({
          showAdmissionProgressDialog: false,
          showAdmissionDialog: false,
          showNonAdmissionDialog: false,
          showRepeatDialog: false,
        });
        if (this.callbacks && this.callbacks.onDone) {
          this.callbacks.onDone();
        }
        this.callbacks = null;
      }

      setFlagforEpl = (epl_gis_epd_api, organizationId, userId) => {
        this.userId = userId
        this.organizationId = organizationId
        this.setState({epl_gis_epd_api: epl_gis_epd_api})
      }

      setPartnerEplFile = (epl_file) => {
        this.setState({partner_epl_file: epl_file})
      }

      signJournals = (deviceId, certificateId, certificatePublicKey) => {
        const rutokenService = getRutokenServiceInstance();
        return this.telemedicService.getEncodedCheckupXml(this.checkupId, certificatePublicKey).then((response) => {
          this.encodedJournal = response.json.data;
          return rutokenService.sign(deviceId, certificateId, this.encodedJournal);
        }).then((signature) => {
          return this.telemedicService.uploadSignedCheckupXml(this.checkupId, this.encodedJournal, signature);
        }).then(() => {
          console.log('Succesfully done!');
        });
      }

      signJournalsForPartners = (deviceId, certificateId, certificatePublicKey) => {
        const rutokenService = getRutokenServiceInstance();
        return this.telemedicService.getT2PartnerEpl(this.checkupId, certificatePublicKey).then((response) => {
          this.encodedJournal = response.json.data;
          return rutokenService.sign(deviceId, certificateId, this.encodedJournal);
        }).then((signature) => {
          return this.telemedicService.uploadT2PartnerEpl(this.checkupId, this.encodedJournal, signature);
        }).then(() => {
          console.log('Succesfully done!');
        });
      }

      render() {
        const {
          showAdmissionProgressDialog, showAdmissionDialog,
          showNonAdmissionDialog, showRepeatDialog, resultAuto,
        } = this.state;
        return (
          <>
            <WrappedComponent
              checkupActions={{
                admitUser: this.admitUser,
                notAdmitUser: this.notAdmitUser,
                notAdmitUserAuto: this.notAdmitUserAuto,
                requestCheckupRepeat: this.requestCheckupRepeat,
                getDoctorVerdict: this.getDoctorVerdict,
                getCheckupReport: this.getCheckupReport,
                getNonAdmissionPaper: this.getNonAdmissionPaper,
                getSobrietyProtocol: this.getSobrietyProtocol,
                getQRCode: this.getQRCode,
                getTaxiWaybill: this.getTaxiWaybill,
                setFlagforEpl: this.setFlagforEpl,
                setPartnerEplFile: this.setPartnerEplFile,
              }}
              {...this.props}
            />
            {showAdmissionProgressDialog && (
              <PromiseProgressDialog
                promiseFactory={this._admitUser}
                onCancel={this._onCancel}
                onDone={this._onDone}
              />
            )}
            {showAdmissionDialog && (
              <AdmissionDialog
                admitUser={this._admitUser}
                resultAuto={resultAuto}
                onCancel={this._onCancel}
                onDone={this._onDone}
              />
            )}
            {showNonAdmissionDialog && (
              <NonAdmissionDialog
                notAdmitUser={this._notAdmitUser}
                resultAuto={resultAuto}
                onCancel={this._onCancel}
                onDone={this._onDone}
              />
            )}
            {showRepeatDialog && (
              <PromiseProgressDialog
                promiseFactory={this._requestCheckupRepeat}
                onCancel={this._onCancel}
                onDone={this._onDone}
              />
            )}
          </>
        );
      }
    }

    return <WithCheckupActions {...props} notify={notify} />;
  };
}

export default withCheckupActions;
