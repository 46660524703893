import React, { useCallback, useState, useEffect } from 'react';
import {
  SimpleShowLayout, TextField, usePermissions, useRefresh, useShowContext,
  useTranslate, BooleanField, useShowController,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';

import FieldLink from '../../components/FieldLink';
import FormatChipField from '../../components/fields/FormatChipField';
import FormatField from '../../components/fields/FormatField';
import FormatValueField from '../../components/fields/FormatValueField';
import GridShowMediator from '../../components/GridShowMediator';
import ImageField from '../../components/fields/ImageField';
import KisArtWorkerOrgsDialog from '../kisArtWorker/KisArtWorkerOrgsDialog';
import ListField from '../../components/fields/ListField';
import RestrictedShow from '../../components/admin/RestrictedShow';
import Signature from '../../components/media/Signature';
import ToggleBanButton from './ToggleBanButton';
import WorkerTitleWithControls from './WorkerTitleWithControls';
import Changelog from '../../components/Changelog';

import { Role } from '../../constants';
import { Action, Section } from '../../permissions';
import { RoutePath, getUrl } from '../../routes';
import { getRole } from '../../storages/auth';
import useTelemedicService from '../../hooks/useTelemedicService';
import {
  formatDisplayDate, formatDisplayDateTime, formatPersonName, formatRole,
} from '../../utils/formatters';

const SERVER_NAME_TO_LABEL = {
  'role': 'telemedic.workers.show.role',
  'login': 'telemedic.workers.show.login',
  'first_name': 'telemedic.workers.form.firstName',
  'middle_name': 'telemedic.workers.form.middleName',
  'last_name': 'telemedic.workers.form.lastName',
  'signature': 'telemedic.workers.show.signature',
  'is_allowed_to_auth_with_esia': 'telemedic.workers.show.isAllowedToAuthWithEsia',
  'esia_id': 'telemedic.workers.show.esiaId',
  'med_organization': 'telemedic.workers.show.medOrganizations',
  'organizations': 'telemedic.workers.show.organizations',
  'inspection_points': 'telemedic.workers.show.inspectionPoints',
  'phone': 'telemedic.workers.show.phone',
  'email': 'telemedic.workers.show.email',
  'ecp_date_from': 'telemedic.workers.show.ecpDateFrom',
  'ecp_date_to': 'telemedic.workers.show.ecpDateTo',
  'ecp': 'telemedic.workers.show.ecp',
  'datetime_created': 'telemedic.workers.show.createdAt',
  'last_auth': 'telemedic.workers.show.lastAuth',
  'is_fired': 'telemedic.workers.show.isFired',
  'photo': 'telemedic.workers.show.photo',
  'license_photo': 'telemedic.workers.show.license'
};

const getMedOrganizationUrl = (record) => (
  record ? getUrl(RoutePath.MED_ORGANIZATION_SHOW, { id: record.id }) : ''
);

const getOrganizationUrl = (record) => (
  record ? getUrl(RoutePath.ORGANIZATION_SHOW, { id: record.id }) : ''
);

const getIspectionPointUrl = (record) => (
  record ? getUrl(RoutePath.INSPECTION_POINT_SHOW, { id: record.id }) : ''
);

const formatNamedItem = (record) => (
  record ? `${record.id} - ${record.name}` : ''
);

const WorkerShow = (props) => {
  const role = getRole();
  const showLicense = [
    Role.ORG_DIRECTOR, Role.ADMIN, Role.MED_ORG_DIRECTOR, Role.SUPPORT,
  ].includes(role);
  const showEcp = [
    Role.ORG_DIRECTOR, Role.ADMIN, Role.MED_ORG_DIRECTOR, Role.SUPPORT,
    Role.PARTNER,
  ].includes(role);
  const extraActions = [];
  const { loaded, permissions } = usePermissions();
  if (loaded) {
    if (permissions.check(Section.WORKERS, Action.BAN)) {
      extraActions.push(<WorkerBanAction key="action1" />);
    }
    if (permissions.check(Section.KIS_ART_WORKERS, Action.READ)) {
      extraActions.push(<WorkerKisArtIdAction key="action2" />);
    }
  }

  const service = useTelemedicService();
  const [changelog, setChangelog] = useState([]);
  const { record } = useShowController(props);
  useEffect(() => {
    if (!record) {
      return;
    }
    service.getWorkerChangelog(record.id).then((response) => {
      if (response.status === 200) {
        setChangelog(response.json.changes);
      }
    });
  }, [record, service]);

  return (
    <RestrictedShow title={<WorkerTitleWithControls />} extraActions={extraActions} {...props}>
      <GridShowMediator container>
        <GridShowMediator xs={12} md={6} item>
          <SimpleShowLayout>
            <FormatValueField
              label="telemedic.workers.show.role"
              source="role"
              formatter={formatRole}
            />
            <TextField label="telemedic.workers.show.login" source="login" />
            <FormatField label="telemedic.workers.show.name" formatter={formatPersonName} />
            <Signature
              label="telemedic.workers.show.signature"
              source="signature"
              addLabel
            />
            <BooleanField
              label="telemedic.workers.show.isAllowedToAuthWithEsia"
              source="is_allowed_to_auth_with_esia"
            />
            <TextField
              label="telemedic.workers.show.esiaId"
              source="esia_id"
            />
            <FormatField
              label="telemedic.workers.show.medOrganizations"
              source="med_organization"
              formatter={(record, source) => (
                record[source] && (
                  <FieldLink getUrl={getMedOrganizationUrl} record={record[source]}>
                    <FormatChipField formatter={formatNamedItem} clickable />
                  </FieldLink>
                )
              )}
            />
            <ListField
              label="telemedic.workers.show.organizations"
              source="organizations"
            >
              <FieldLink getUrl={getOrganizationUrl}>
                <FormatChipField formatter={formatNamedItem} clickable />
              </FieldLink>
            </ListField>
            <ListField
              label="telemedic.workers.show.inspectionPoints"
              source="inspection_points"
            >
              <FieldLink getUrl={getIspectionPointUrl}>
                <FormatChipField formatter={formatNamedItem} clickable />
              </FieldLink>
            </ListField>
            <TextField label="telemedic.workers.show.phone" source="phone" />
            <TextField label="telemedic.workers.show.email" source="email" />
            {showEcp && (
              <FormatValueField
                label="telemedic.workers.show.ecpDateFrom"
                source="ecp_date_from"
                formatter={formatDisplayDate}
              />
            )}
            {showEcp && (
              <FormatValueField
                label="telemedic.workers.show.ecpDateTo"
                source="ecp_date_to"
                formatter={formatDisplayDate}
              />
            )}
            {showEcp && (
              <TextField label="telemedic.workers.show.ecp" source="ecp" />
            )}
            <FormatValueField
              label="telemedic.workers.show.createdAt"
              source="datetime_created"
              formatter={formatDisplayDateTime}
            />
            <FormatValueField
              label="telemedic.workers.show.lastAuth"
              source="last_auth"
              formatter={formatDisplayDateTime}
            />
            <BooleanField
              label="telemedic.workers.show.isFired"
              source="is_fired"
              sortable
            />
            <Changelog
              changelog={changelog}
              fieldsLabels={SERVER_NAME_TO_LABEL}
            />
          </SimpleShowLayout>
        </GridShowMediator>
        <GridShowMediator xs={12} md={6} item>
          <ImageField
            label="telemedic.workers.show.photo"
            source="photo"
          />
          {showLicense && (
            <ImageField
              label="telemedic.workers.show.license"
              source="license_photo"
            />
          )}
        </GridShowMediator>
      </GridShowMediator>
    </RestrictedShow>
  );
};

const WorkerBanAction = (props) => {
  const refresh = useRefresh();
  const { record } = useShowContext(props);
  const onDone = useCallback(() => refresh(), [refresh]);
  return (
    <ToggleBanButton onDone={onDone} record={record} />
  );
};

const WorkerKisArtIdAction = (props) => {
  const translate = useTranslate();
  const { record } = useShowContext(props);
  const workerId = record ? record.id : undefined;
  const [kisArtDialogOpened, setKisArtDialogOpened] = useState(false);
  const openKisArtDialog = useCallback(() => {
    setKisArtDialogOpened(true);
  }, []);
  const closeKisArtDialog = useCallback(() => {
    setKisArtDialogOpened(false);
  }, []);
  return (
    <>
      <Button
        onClick={openKisArtDialog}
        size="small"
        startIcon={<ListAltIcon />}
      >
        { translate('telemedic.common.kisArtId') }
      </Button>
      <KisArtWorkerOrgsDialog
        open={kisArtDialogOpened}
        onClose={closeKisArtDialog}
        workerId={workerId}
      />
    </>
  );
};

export default WorkerShow;
