import React from 'react';
import {
  BooleanInput, FormDataConsumer, SelectInput, TextInput, required, useTranslate,
} from 'react-admin';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import RestrictedSimpleForm from '../../components/admin/RestrictedSimpleForm';
import LargeCheckListInput from '../../components/inputs/LargeCheckListInput';
import styles from './OrganizationForm.module.css';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';

import { ContractStatus, PriceType, Role, PartnersEplApi, OrganizationType } from '../../constants';
import { getRole } from '../../storages/auth';
import { enumChoices } from '../../utils/enums';
import { formatContractStatus, formatPriceType, formatPartnersEplApi, formatOrganizationType } from '../../utils/formatters';
import { ValueTypes } from '../../utils/values';
import { isDecimal } from '../../utils/validators';

const CAN_EDIT_GENERAL_DATA = [Role.ADMIN];
const CAN_EDIT_DEALER_DATA = [Role.ADMIN, Role.PARTNER];
const CAN_EDIT_KIS_ART_DATA = [Role.ADMIN, Role.PARTNER];
const CAN_EDIT_FINANCIAL_DATA = [Role.ADMIN, Role.MANAGER];
const CAN_EDIT_LEGAL_DATA = [Role.ADMIN, Role.PARTNER];
const CAN_EDIT_BLOCK_DATA = [Role.ADMIN, Role.SUPPORT];

const OrganizationForm = ({ getInputProps, ...rest }) => {
  const service = useTelemedicService();
  const terminalsValue = useTelemedicServiceJsonValue(() => service.getOrganizationFormTerminalValues(), null, []);
  const translate = useTranslate();
  const dealers = useTelemedicServiceJsonValue(
    () => service.getOrganizationFormDealerValues(), null, [],
  );
  const dealersResponsible = useTelemedicServiceJsonValue(
    () => service.getOrganizationFormDealersResponsibleValues(), null, [],
  );
  const role = getRole();
  const userIsAdmin = (getRole() === Role.ADMIN);
  const canEditGeneralData = CAN_EDIT_GENERAL_DATA.includes(role);
  const canEditDealerData = CAN_EDIT_DEALER_DATA.includes(role);
  const canEditFinancialData = CAN_EDIT_FINANCIAL_DATA.includes(role);
  const canEditLegalData = CAN_EDIT_LEGAL_DATA.includes(role);
  const canEditKisArtData = CAN_EDIT_KIS_ART_DATA.includes(role);
  const canEditBlockData = CAN_EDIT_BLOCK_DATA.includes(role);

  return (
    <RestrictedSimpleForm className={styles.compactForm} {...rest}>
      <div className={styles.row}>
        {
          canEditLegalData && (
            <div className={styles.columnForm}>
              <div className={styles.formTitle}>Юридические данные о клиенте</div>
              <TextInput
                label="telemedic.organizations.form.name"
                source="name"
                validate={required()}
                {...getInputProps('name')}
              />
              <div className={styles.row}>
                <TextInput
                  label="telemedic.organizations.form.inn"
                  source="inn"
                  validate={required()}
                  {...getInputProps('inn')}
                />
                <TextInput
                  label="telemedic.organizations.form.kpp"
                  source="kpp"
                  validate={required()}
                  {...getInputProps('kpp')}
                />
                <TextInput
                  label="telemedic.organizations.form.ogrn"
                  source="ogrn"
                  validate={required()}
                  {...getInputProps('ogrn')}
                />
              </div>
              <TextInput
                label="telemedic.organizations.form.affiliate_number"
                source="affiliate_number"
                {...getInputProps('affiliate_number')}
              />
              <TextInput
                label="telemedic.organizations.form.address"
                source="address"
                {...getInputProps('address')}
              />
              <SelectInput
                label="telemedic.organizations.form.contract"
                source="contract_status"
                choices={enumChoices(ContractStatus, formatContractStatus)}
                validate={required()}
                {...getInputProps('contract_status')}
              />
            </div>
          )
        }

        <div className={styles.columnForm}>
          <div className={styles.formTitle}>Организационные данные о клиенте</div>

          <TextInput
            label="telemedic.organizations.form.region"
            source="region"
            validate={required()}
            {...getInputProps('region')}
          />
          {canEditDealerData && (
            <AutocompleteInput
              label="telemedic.organizations.form.dealer"
              choices={dealers}
              choiceLabelField="name"
              choiceValueField="id"
              source="dealer_id"
              validate={required()}
              {...getInputProps('dealer_id')}
            />
          )}
          {canEditDealerData && (
            <AutocompleteInput
              label="telemedic.organizations.form.dealersResponsible"
              choices={dealersResponsible}
              choiceLabelField="name"
              choiceValueField="id"
              source="dealers_responsible_id"
              {...getInputProps('dealers_responsible_id')}
            />
          )}
          <div className={styles.row}>
            <TextInput
              label="telemedic.organizations.form.responsibleName"
              source="responsible_name"
              {...getInputProps('responsible_name')}
            />
            <TextInput
              label="telemedic.organizations.form.responsiblePhone"
              source="responsible_phone"
              {...getInputProps('responsible_phone')}
            />
            <TextInput
              label="telemedic.organizations.form.responsibleEmail"
              source="responsible_emails"
              {...getInputProps('responsible_emails')}
            />
          </div>
          <TextInput
            label="telemedic.organizations.form.contacts"
            source="contacts"
            multiline
            {...getInputProps('contacts')}
          />
          <TextInput
            label="telemedic.organizations.form.comments"
            source="comments"
            multiline
            {...getInputProps('comments')}
          />
        </div>
      </div>
      {
        (canEditKisArtData || canEditGeneralData) && (
          <div className={styles.columnForm}>
            <div className={styles.formTitle}>Общие настройки</div>
            <div className={styles.additionalInfo}>
              {
                canEditKisArtData && (
                  <BooleanInput
                    label="telemedic.organizations.form.isKisArtRelated"
                    source="is_kis_art_related"
                    {...getInputProps('is_kis_art_related')}
                  />
                )
              }
              <FormDataConsumer>
                {
                  ({ formData }) => formData.is_kis_art_related && canEditKisArtData && (
                    <TextInput
                      label="telemedic.common.kisArtId"
                      source="kis_art_id"
                      {...getInputProps('kis_art_id')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.is_kis_art_related && canEditKisArtData && (
                    <TextInput
                      label="telemedic.organizations.form.kisArtClientId"
                      source="kis_art_client_id"
                      {...getInputProps('kis_art_client_id')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.is_kis_art_related && canEditKisArtData && (
                    <TextInput
                      label="telemedic.organizations.form.kisArtClientSecret"
                      source="kis_art_client_secret"
                      {...getInputProps('kis_art_client_secret')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.is_kis_art_related && canEditKisArtData && (
                    <TextInput
                      label="telemedic.organizations.form.operatorKisArtId"
                      source="operator_kis_art_id"
                      {...getInputProps('operator_kis_art_id')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.is_kis_art_related && canEditKisArtData && (
                    <TextInput
                      label="telemedic.organizations.form.medicKisArtId"
                      source="medic_kis_art_id"
                      {...getInputProps('medic_kis_art_id')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.is_kis_art_related && canEditKisArtData && (
                    <TextInput
                      label="telemedic.organizations.form.mechanicKisArtId"
                      source="mechanic_kis_art_id"
                      {...getInputProps('mechanic_kis_art_id')}
                    />
                  )
                }
              </FormDataConsumer>
              {
                canEditGeneralData && (
                  <BooleanInput
                    label="telemedic.organizations.form.eplGisEpdAPI"
                    source="epl_gis_epd_api"
                    {...getInputProps('epl_gis_epd_api')}
                  />
                )
              }
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.partnerApiEpl"
                  source="partner_api_epl"
                  {...getInputProps('partner_api_epl')}
                />
              )}
              <FormDataConsumer>
                {
                  ({ formData }) => formData.partner_api_epl && canEditGeneralData && (
                    <SelectInput
                      label="telemedic.organizations.form.partnerApiEplName"
                      source="partner_api_epl_name"
                      choices={enumChoices(PartnersEplApi, formatPartnersEplApi)}
                      {...getInputProps('partner_api_epl_name')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.partner_api_epl && canEditGeneralData && formData.partner_api_epl_name === PartnersEplApi.EDIWEB && (
                    <TextInput
                      label="telemedic.organizations.form.ediwebGlnOrg"
                      source="ediweb_gln_org"
                      {...getInputProps('ediweb_gln_org')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
            {
              ({ formData }) => formData.partner_api_epl && canEditGeneralData && formData.partner_api_epl_name === PartnersEplApi.KONTUR && (
                <TextInput
                  label="telemedic.organizations.form.partnerApiOrgId"
                  source="partner_api_org_id"
                  {...getInputProps('partner_api_org_id')}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {
              ({ formData }) => formData.partner_api_epl && canEditGeneralData && formData.partner_api_epl_name === PartnersEplApi.KONTUR && (
                <TextInput
                  label="telemedic.organizations.form.partnerApiLogin"
                  source="partner_api_login"
                  {...getInputProps('partner_api_login')}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {
              ({ formData }) => formData.partner_api_epl && canEditGeneralData && formData.partner_api_epl_name === PartnersEplApi.KONTUR && (
                <TextInput
                  label="telemedic.organizations.form.partnerApiPassword"
                  source="partner_api_password"
                  {...getInputProps('partner_api_password')}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {
              ({ formData }) => formData.partner_api_epl && canEditGeneralData && formData.partner_api_epl_name === PartnersEplApi.KONTUR && (
                <TextInput
                  label="telemedic.organizations.form.partnerApiMedicInn"
                  source="partner_api_medic_inn"
                  {...getInputProps('partner_api_medic_inn')}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {
              ({ formData }) => formData.partner_api_epl && canEditGeneralData && formData.partner_api_epl_name === PartnersEplApi.KONTUR && (
                <TextInput
                  label="telemedic.organizations.form.partnerApiMedicKpp"
                  source="partner_api_medic_kpp"
                  {...getInputProps('partner_api_medic_kpp')}
                />
              )
            }
          </FormDataConsumer>
          {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.alcoholSMS"
                  source="sett_sms"
                  {...getInputProps('sett_sms')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.alcoholEmail"
                  source="sett_alcohol_email"
                  {...getInputProps('sett_alcohol_email')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.notAdmitNotifyEmail"
                  source="sett_not_admit_notify_email"
                  {...getInputProps('sett_not_admit_notify_email')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.temperatureInDegrees"
                  source="sett_temperature_in_degrees"
                  {...getInputProps('sett_temperature_in_degrees')}
                />
              )}
              <BooleanInput
                label="telemedic.organizations.form.autoinspect"
                source="sett_autoinspect"
                {...getInputProps('sett_autoinspect')}
              />
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.repeats"
                  source="sett_repeats"
                  {...getInputProps('sett_repeats')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.enableFaceId"
                  source="sett_face_id"
                  {...getInputProps('sett_face_id')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.procPersData"
                  source="proc_pers_data"
                  {...getInputProps('proc_pers_data')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.useOrganizationBilling"
                  source="sett_org_billing"
                  {...getInputProps('sett_org_billing')}
                />
              )}
              {canEditGeneralData && (
                <BooleanInput
                  label="telemedic.organizations.form.useLocalTime"
                  source="sett_local_time"
                  {...getInputProps('sett_local_time')}
                />
              )}
            </div>
          </div>
        )
      }

      <FormDataConsumer>
        {
          ({ formData }) => formData.orgastral === false && formData.epl_gis_epd_api === true && userIsAdmin && (
            <div className={styles.row}>
              <div className={styles.columnForm}>
                <div className={styles.formTitle}>Данные о клиенте для ЭПЛ</div>
                <TextInput
                  label="telemedic.organizations.form.name"
                  source="name"
                  {...getInputProps('name')}
                />
                <SelectInput
                  label="telemedic.organizations.form.organization_type"
                  source="organization_type"
                  choices={enumChoices(OrganizationType, formatOrganizationType)}
                  {...getInputProps('organization_type')}
                />
                <TextInput
                  label="telemedic.organizations.form.full_name"
                  source="full_name"
                  {...getInputProps('full_name')}
                />
                <TextInput
                  label="telemedic.organizations.form.inn"
                  source="inn"
                  {...getInputProps('inn')}
                />
                <TextInput
                  label="telemedic.organizations.form.ogrn"
                  source="ogrn"
                  {...getInputProps('ogrn')}
                />
                <TextInput
                  label="telemedic.organizations.form.kpp"
                  source="kpp"
                  {...getInputProps('kpp')}
                />
                <h6>Юридический адрес организации</h6>
                <div className={styles.row}>
                  <TextInput
                    label="telemedic.organizations.form.legal_region_code"
                    source="legal_region_code"
                    {...getInputProps('legal_region_code')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_city"
                    source="legal_city"
                    {...getInputProps('legal_city')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_settlement"
                    source="legal_settlement"
                    {...getInputProps('legal_settlement')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_index"
                    source="legal_index"
                    {...getInputProps('legal_index')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_neighborhood"
                    source="legal_neighborhood"
                    {...getInputProps('legal_neighborhood')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_street"
                    source="legal_street"
                    {...getInputProps('legal_street')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_house"
                    source="legal_house"
                    {...getInputProps('legal_house')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_building"
                    source="legal_building"
                    {...getInputProps('legal_building')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.legal_apartment"
                    source="legal_apartment"
                    {...getInputProps('legal_apartment')}
                  />
                </div>
                <h6>Фактический адрес организации</h6>
                <div className={styles.row}>
                  <TextInput
                    label="telemedic.organizations.form.actual_region_code"
                    source="actual_region_code"
                    {...getInputProps('actual_region_code')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_city"
                    source="actual_city"
                    {...getInputProps('actual_city')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_settlement"
                    source="actual_settlement"
                    {...getInputProps('actual_settlement')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_index"
                    source="actual_index"
                    {...getInputProps('actual_index')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_neighborhood"
                    source="actual_neighborhood"
                    {...getInputProps('actual_neighborhood')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_street"
                    source="actual_street"
                    {...getInputProps('actual_street')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_house"
                    source="actual_house"
                    {...getInputProps('actual_house')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_building"
                    source="actual_building"
                    {...getInputProps('actual_building')}
                  />
                  <TextInput
                    label="telemedic.organizations.form.actual_apartment"
                    source="actual_apartment"
                    {...getInputProps('actual_apartment')}
                  />
                </div>
                <TextInput
                  label="telemedic.organizations.form.org_phone"
                  source="org_phone"
                  {...getInputProps('org_phone')}
                />
                <TextInput
                  label="telemedic.organizations.form.org_email"
                  source="org_email"
                  {...getInputProps('org_email')}
                />

              </div>
            </div>
          )
        }
      </FormDataConsumer>

      {
        canEditBlockData && (
          <div className={styles.columnForm}>
            <div className={styles.formTitle}>Блокировки</div>
            <div className={styles.additionalInfo}>
              <BooleanInput
                label="telemedic.organizations.form.alcoholBlock"
                source="alcohol_block"
                {...getInputProps('alcohol_block')}
              />
              <FormDataConsumer>
                {
                  ({ formData }) => formData.alcohol_block && (
                    <TextInput
                      label="telemedic.organizations.form.durationMinutes"
                      source="duration_minutes_alcohol_block"
                      {...getInputProps('duration_minutes_alcohol_block')}
                    />
                  )
                }
              </FormDataConsumer>
              <BooleanInput
                label="telemedic.organizations.form.checkupBlock"
                source="checkup_block"
                {...getInputProps('checkup_block')}
              />
              <FormDataConsumer>
                {
                  ({ formData }) => formData.checkup_block && (
                    <TextInput
                      label="telemedic.organizations.form.durationMinutes"
                      source="duration_minutes_checkup_block"
                      {...getInputProps('duration_minutes_checkup_block')}
                    />
                  )
                }
              </FormDataConsumer>
              <BooleanInput
                label="telemedic.organizations.form.threeCheckupBlock"
                source="three_checkup_block"
                {...getInputProps('three_checkup_block')}
              />
              <FormDataConsumer>
                {
                  ({ formData }) => formData.three_checkup_block && (
                    <TextInput
                      label="telemedic.organizations.form.durationMinutes"
                      source="duration_minutes_three_checkup_block"
                      {...getInputProps('duration_minutes_three_checkup_block')}
                    />
                  )
                }
              </FormDataConsumer>
              <BooleanInput
                label="telemedic.organizations.form.limitPreTrip"
                source="limit_pre_trip"
                {...getInputProps('limit_pre_trip')}
              />
              <FormDataConsumer>
                {
                  ({ formData }) => formData.limit_pre_trip && (
                    <TextInput
                      label="telemedic.organizations.form.timeFrom"
                      source="time_from_limit_pre_trip"
                      {...getInputProps('time_from_limit_pre_trip')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.limit_pre_trip && (
                    <TextInput
                      label="telemedic.organizations.form.timeTo"
                      source="time_to_limit_pre_trip"
                      {...getInputProps('time_to_limit_pre_trip')}
                    />
                  )
                }
              </FormDataConsumer>
              <BooleanInput
                label="telemedic.organizations.form.limitPostTrip"
                source="limit_post_trip"
                {...getInputProps('limit_post_trip')}
              />
              <FormDataConsumer>
                {
                  ({ formData }) => formData.limit_post_trip && (
                    <TextInput
                      label="telemedic.organizations.form.timeFrom"
                      source="time_from_limit_post_trip"
                      {...getInputProps('time_from_limit_post_trip')}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {
                  ({ formData }) => formData.limit_post_trip && (
                    <TextInput
                      label="telemedic.organizations.form.timeTo"
                      source="time_to_limit_post_trip"
                      {...getInputProps('time_to_limit_post_trip')}
                    />
                  )
                }
              </FormDataConsumer>
            </div>
          </div>
        )
      }
      {
        canEditFinancialData && (
          <div className={styles.columnForm}>
            <div className={styles.formTitle}>Финансовые данные</div>
            <div className={styles.rowWrap}>
              <div className={styles.columnCompactForm}>
                <div className={styles.formSubTitle}>Оплата ПО (медосмотры)</div>
                <SelectInput
                  label="telemedic.organizations.form.priceType"
                  source="price_type"
                  choices={enumChoices(PriceType, formatPriceType)}
                  validate={required()}
                  {...getInputProps('price_type')}
                />
                <TextInput
                  label="telemedic.organizations.form.price"
                  source="price"
                  validate={[required(), isDecimal(translate)]}
                  {...getInputProps('price')}
                />
                <TextInput
                  label="telemedic.organizations.form.techInspectionPrice"
                  source="tech_inspection_price"
                  validate={[required(), isDecimal(translate)]}
                  {...getInputProps('tech_inspection_price')}
                />
                <BooleanInput
                  label="telemedic.organizations.form.inspectionPackage"
                  source="inspection_package"
                  {...getInputProps('inspection_package')}
                />
                <FormDataConsumer>
                  {
                    ({ formData }) => formData.inspection_package && (
                      <TextInput
                        label="telemedic.organizations.form.priceInspectionPackage"
                        source="price_inspection_package"
                        {...getInputProps('price_inspection_package')}
                      />
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {
                    ({ formData }) => formData.inspection_package && (
                      <TextInput
                        label="telemedic.organizations.form.overPriceInspectionPackage"
                        source="over_price_inspection_package"
                        {...getInputProps('over_price_inspection_package')}
                      />
                    )
                  }
                </FormDataConsumer>
                <BooleanInput
                  label="telemedic.organizations.form.paidPreInspections"
                  source="sett_paid_pre_inspections"
                  {...getInputProps('sett_paid_pre_inspections')}
                />
                <BooleanInput
                  label="telemedic.organizations.form.shouldNotCountRepeatedCheckups"
                  source="should_not_count_repeated_checkups"
                  {...getInputProps('should_not_count_repeated_checkups')}
                />
              </div>

              <div className={styles.columnCompactForm}>
                <div className={styles.formSubTitle}>Оплата УСЛУГИ (медосмотры)</div>
                <SelectInput
                  label="telemedic.organizations.form.priceTypeDealer"
                  source="dealer_price_type"
                  choices={enumChoices(PriceType, formatPriceType)}
                  {...getInputProps('dealer_price_type')}
                />
                <TextInput
                  label="telemedic.organizations.form.dealerPrice"
                  source="dealer_price"
                  validate={[required(), isDecimal(translate)]}
                  {...getInputProps('dealer_price')}
                />
                <TextInput
                  label="telemedic.organizations.form.techInspectionDealerPrice"
                  source="tech_inspection_dealer_price"
                  validate={[required(), isDecimal(translate)]}
                  {...getInputProps('tech_inspection_dealer_price')}
                />
                <BooleanInput
                  label="telemedic.organizations.form.inspectionPackage"
                  source="inspection_package"
                  {...getInputProps('inspection_package')}
                />
                <FormDataConsumer>
                  {
                    ({ formData }) => formData.inspection_package && (
                      <TextInput
                        label="telemedic.organizations.form.priceInspectionPackage"
                        source="price_inspection_package"
                        {...getInputProps('price_inspection_package')}
                      />
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {
                    ({ formData }) => formData.inspection_package && (
                      <TextInput
                        label="telemedic.organizations.form.overPriceInspectionPackage"
                        source="over_price_inspection_package"
                        {...getInputProps('over_price_inspection_package')}
                      />
                    )
                  }
                </FormDataConsumer>
                <BooleanInput
                  label="telemedic.organizations.form.paidPreInspectionsForDealer"
                  source="sett_paid_pre_inspections_for_dealer"
                  {...getInputProps('sett_paid_pre_inspections_for_dealer')}
                />
                <BooleanInput
                  label="telemedic.organizations.form.shouldNotCountRepeatedCheckupsForDealer"
                  source="should_not_count_repeated_checkups_for_dealer"
                  {...getInputProps('should_not_count_repeated_checkups_for_dealer')}
                />
              </div>

              <div className={styles.columnCompactForm}>
                <div className={styles.formSubTitle}>Аренда оборудования (нерабочее)</div>
                <BooleanInput
                  label="telemedic.organizations.form.EquipmentIsRent"
                  source="equipment_is_rent"
                  {...getInputProps('equipment_is_rent')}
                />
                <LargeCheckListInput
                  label="telemedic.organizations.form.chooseTerminals"
                  choices={terminalsValue}
                  choiceLabelField="name"
                  choiceValueField="id"
                  source="terminals_id"
                  valueType={ValueTypes.INTEGER}
                  {...getInputProps('terminals_id')}
                />
                <TextInput
                  label="telemedic.organizations.form.monthlyEquipmentPay"
                  source="monthly_equipment_pay"
                  {...getInputProps('monthly_equipment_pay')}
                />
              </div>

              <div className={styles.columnCompactForm}>
                <div className={styles.formSubTitle}>Оплата ПО (ЭПЛ ГИС ЭПД) (нерабочее)</div>
              </div>

              <div className={styles.columnCompactForm}>
                <div className={styles.formSubTitle}>Оплата ПО (ЭПЛ ГИС ЭПД) (нерабочее)</div>
              </div>

            </div>
          </div>
        )
      }
    </RestrictedSimpleForm>
  );
};

export default OrganizationForm;
